import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { productAffiliateList, steps } from "../assets/data/datalist";
import AdvisorToggleSection from "../components/AdvisorToggleSection";
import ConversationEmbedded from "../components/ConversationEmbedded";
import Footer from "../components/Footer";
import GifSection from "../components/GifSection";
import HeadMenu from "../components/HeadMenu";
import LoaderScreen from "../components/LoaderScreen";
import PackageSection from "../components/PackageSection";
import TrustBox from "../components/TrustBox";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { useMediaQuery } from "../services/hooks/useMediaQuery";
import {
    getBankIdIcon,
    getButtonArrow,
    getChatIcon,
    getQuoteIcon,
    getShortDownArrowIcon
} from "../services/icons";
import {
  defaultPackagePrice,
  getPartnerByUrl,
  PartnerType,
} from "../services/partners";
import { homeRedirectKey, partnerKey, setInStorage } from "../services/storage";
import { useScrollPosition } from "../services/utils";

const gifThumb = new URL(
  "../assets/video/image_thumb_tn.jpg",
  import.meta.url
);
const heroImg = new URL("../assets/images/partner.jpg", import.meta.url);

export default function AffiliatePage() {
  const [partner, setPartner] = useState<PartnerType>();
  const [loading, setLoading] = useState(true);
  const [showConversationModal, setShowConversationModal] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const scrollPosition = useScrollPosition();
  const navigate = useNavigate();
  const { pt } = useParams<{
    pt: string;
  }>();

  useEffect(() => {
    setInStorage(homeRedirectKey, window.location.pathname);

    if (pt) {
      const partnerExist = getPartnerByUrl(pt);
      if (partnerExist) {
        setInStorage(partnerKey, pt);
        setPartner(partnerExist);
      } else {
        navigate("/partner/");
        setLoading(false);
      }
    } else {
      setPartner(null);
    }
  }, []);

  useEffect(() => {
    if (partner !== undefined) {
      setLoading(false);
    }
    if (partner) {
      document.title = partner.pageTitle;
      /* If you need to add custom bullet points, do it like this: */
      /*
      partner?.url === "bostadsratterna"
        ? productAffiliateList.push({
            text: "Prenumerationsavgift för år 1 (därefter 449 kr per år).",
          })
        : productAffiliateList;
      */
    }
  }, [partner]);

  useEffect(() => {
    if (showConversationModal) {
      setShowConversationModal(false);
    }
  }, [showConversationModal]);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }

  const renderLoginButton = (buttonClass: string, iconColor: string) => {
    return (
      <Link
        to="/login"
        className={
          `inline-flex items-center gap-2 rounded border-[1px] border-white bg-transparent py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-[#fff] hover:border-[#CECABA] hover:text-[#CECABA] lg:gap-3 lg:self-end` +
          buttonClass
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon(iconColor, "20", "100%")
          : getBankIdIcon("#fff", "20", "100%")}
        Kom igång
      </Link>
    );
  };

  const renderAcuityCalendarButton = (
    buttonClass: string,
    iconColor: string
  ) => {
    return (
      <Link
        target="_blank"
        to="https://app.acuityscheduling.com/schedule.php?owner=18650906&appointmentType=category:Fenix%20Life"
        className={
          `inline-flex items-center gap-2 rounded border-[1px] border-white bg-transparent py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-[#fff] hover:border-[#CECABA] hover:text-[#CECABA] lg:gap-3 lg:self-end` +
          buttonClass
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getChatIcon(iconColor, "20", "100%")
          : getChatIcon("#fff", "20", "100%")}
        Frågor? Boka samtal
      </Link>
    );
  };

  const renderAcuityLegalCalendarButton = (
    buttonClass: string,
    iconColor: string
  ) => {
    return (
      <Link
        target="_blank"
        to="https://app.acuityscheduling.com/schedule.php?owner=18650906&appointmentType=64041750&field:16199987=SPFSeniorerna"
        className={
          `inline-flex items-center gap-2 rounded border-[1px] border-white bg-transparent py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-[#fff] hover:border-[#CECABA] hover:text-[#CECABA] lg:gap-3 lg:self-end` +
          buttonClass
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getChatIcon(iconColor, "20", "100%")
          : getChatIcon("#fff", "20", "100%")}
        Boka din rådgivning här
      </Link>
    );
  };

  const renderHomePageButton = (
    buttonClass: string,
    iconColor: string
  ) => {
    return (
      <Link
        target="_blank"
        to="https://fenixbegravning.se/?utm_source=spfseniorerna&utm_medium=referral&utm_campaign=begravning"
        className={
          `inline-flex items-center gap-2 rounded border-[1px] border-white bg-transparent py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-[#fff] hover:border-[#CECABA] hover:text-[#CECABA] lg:gap-3 lg:self-end` +
          buttonClass
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getButtonArrow(iconColor, "20", "100%")
          : getButtonArrow("#fff", "20", "100%")}
          Besök vår hemsida här
      </Link>
    );
  };

  const getHeaderText = (url) => {
    switch (url) {
      case "spf-seniorerna":
        return "Erbjudande";
      case "bostadsratterna":
        return "1000 kostnadsfria digitalt skapade testamenten och framtidsfullmakter - först till kvarn";
      default:
        return "Livsplanering - tryggt och enkelt";
    }
  };

  const renderBulletPoints = () => (
    <p className="mb-3 whitespace-pre-wrap">
      <ul className="flex  flex-col items-start justify-start self-stretch pl-8 pt-3">
        <li className="relative shrink grow basis-0 pb-4 text-[13px] font-normal italic leading-4 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
          Digitalt framställt testamente och framtidsfullmakt för nedladdning: kostnadsfritt
        </li>
        <li className="relative shrink grow basis-0 pb-4 text-[13px] font-normal italic leading-4 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
          Utskick av testamente med förfrankerat kuvert för retur till Fenix Family och säker
          förvaring och övervakning under första året: 199 kronor
        </li>
        <li className="relative shrink grow basis-0 pb-4 text-[13px] font-normal italic leading-4 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
          Säker förvaring och övervakning av testamente efter första året: 99 kronor per år
        </li>
      </ul>
    </p>
  );

  const renderPartnerContent = (url) => {
    switch (url) {
      case "dalarna":
      case "convendum":
        return (
          <>
            <p className="mb-3 whitespace-pre-wrap">
              När livet förändras – ta kontroll över framtiden och undvik konflikter.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Livet är fyllt av stora händelser som påverkar både känslor och ekonomi.
              Oavsett om du vill hantera frågor kring arv eller skydda dig vid sjukdom,
              är det viktigt att du hanterar de juridiska aspekterna som kan uppstå.
            </p>
            {renderBulletPoints()}
            <div className="mb-3 justify-center">
              {renderAcuityCalendarButton(
                  ` !bg-deep-blue !text-white hover:!border-[#6F86A0] hover:!bg-[#6F86A0]`,
                  `#fff`
              )}
            </div>
          </>
        );
      case "spf-seniorerna":
        return (
          <>
            <p className="mb-3 whitespace-pre-wrap">
              När livet förändras – ta kontroll över framtiden och undvik konflikter.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Livet är fyllt av stora händelser som påverkar både känslor och ekonomi.
              Oavsett om du vill hantera frågor kring arv eller skydda dig vid sjukdom,
              är det viktigt att du hanterar de juridiska aspekterna som kan uppstå.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Därför lanserar Fenix Family ett erbjudande med SPF Seniorerna där du som medlem
              får testamente och framtidsfullmakt via Fenix Familys digitala livsplaneringsverktyg 
              för 3999 kr (ordinarie pris 5999 kr).
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              <ul className="flex flex-col items-start justify-start self-stretch pl-8 pt-3">
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  Inventering av din livssituation med hjälp av Fenix Familys digitala verktyg
                </li>
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  Digitalt testamente och framtidsfullmakt för nedladdning
                </li>
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  Trygg digital åtkomst och förvaring genom BankID
                </li>
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  Utskick av testamente med förfrankerat kuvert för retur till Fenix Family
                </li>
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  Säker förvaring och övervakning av testamente
                </li>
              </ul>
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Det här är inte bara en tjänst – det är en möjlighet att slippa den där gnagande oron.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Årsavgiften efter första året är 599 kr/år.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Vi reserverar oss för eventuella fel och förbehåller oss rätten att
              dra tillbaka eller ändra erbjudandet utan föregående meddelande.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              &nbsp;
            </p>
            <div className="mb-3 justify-center">
              {renderAcuityCalendarButton(
                  ` !bg-deep-blue !text-white hover:!border-[#6F86A0] hover:!bg-[#6F86A0]`,
                  `#fff`
              )}
            </div>
            <p className="mb-3 whitespace-pre-wrap">
              &nbsp;
            </p>
            <h1 className="mb-4 font-interblack text-[28px] italic leading-[31px] lg:mb-4 lg:text-[48px] lg:leading-[53px]">
              Personlig juridikhjälp när du behöver det
            </h1>
            <p className="mb-3 whitespace-pre-wrap">
              För vissa frågor kan det kännas tryggt att prata med en expert. 
              Därför erbjuder vi även personlig rådgivning med våra erfarna jurister. 
              De hjälper dig att navigera i juridiken och hitta lösningar 
              som passar just din familjesituation.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Som medlem får du:
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              <ul className="flex flex-col items-start justify-start self-stretch pl-8 pt-3">
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  20 % rabatt på avtal med fast pris
                </li>
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  30 minuter kostnadsfri rådgivning vid beställning av juridiska avtal eller 
                  bouppteckning på löpande räkning
                </li>
              </ul>
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              &nbsp;
            </p>
            <div className="mb-3 justify-center">
              {renderAcuityLegalCalendarButton(
                ` !bg-deep-blue !text-white hover:!border-[#6F86A0] hover:!bg-[#6F86A0]`,
                `#fff`
              )}
            </div>
            <p className="mb-3 whitespace-pre-wrap">
              &nbsp;
            </p>
            <h1 className="mb-4 font-interblack text-[28px] italic leading-[31px] lg:mb-4 lg:text-[48px] lg:leading-[53px]">
              Ett avsked med värdighet och omtanke
            </h1>
            <p className="mb-3 whitespace-pre-wrap">
              Att ta farväl av en älskad person är en av livets mest känslosamma stunder. 
              Vi på Fenix Family finns här för att hjälpa dig skapa ett avsked som 
              speglar era önskemål och hedrar den avlidne på bästa sätt. Oavsett 
              om det handlar om en borgerlig, kyrklig eller trosbaserad ceremoni finns 
              vi vid din sida genom hela processen.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Som medlem får du:
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              <ul className="flex flex-col items-start justify-start self-stretch pl-8 pt-3">
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  500 kr rabatt på begravningar
                </li>
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  Ytterligare 500 kr rabatt om det finns en förplanering
                </li>
              </ul>
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Ring oss på <a className="underline" href={`tel:+46101389386`}>010 138 93 86</a>
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              &nbsp;
            </p>
            <div className="mb-3 justify-center">
              {renderHomePageButton(
                ` !bg-deep-blue !text-white hover:!border-[#6F86A0] hover:!bg-[#6F86A0]`,
                `#fff`
              )}
            </div>
          </>
        );
      case "bostadsratterna":
        return (
          <>
            <p className="mb-3 whitespace-pre-wrap">
              När livet förändras – ta kontroll över framtiden och undvik konflikter.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Livet är fyllt av stora händelser som påverkar både känslor och ekonomi.
              Oavsett om du vill hantera frågor kring arv eller skydda dig vid sjukdom,
              är det viktigt att du hanterar de juridiska aspekterna som kan uppstå.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Därför lanserar Fenix Family ett nytt samarbete med Bostadsrätterna där du som bor i en
              medlemsförening hos Bostadsrätterna får kostnadsfritt testamente och framtidsfullmakt via Fenix Familys
              digitala livsplaneringsverktyg (ordinarie pris 5999 kr).
            </p>
            <h1 className="mb-4 font-interblack text-[20px] italic leading-[23px] lg:mb-4 lg:text-[36px] lg:leading-[42px]">
              Kampanjen gäller till 30 april 2025:
            </h1>
            <p className="mb-3 whitespace-pre-wrap">
              <ul className="flex flex-col items-start justify-start self-stretch pl-8 pt-3">
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  Först till kvarn: 1 000 kostnadsfria digitalt skapade testamenten och framtidsfullmakter
                </li>
              </ul>
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Kostnadsfritt:
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              <ul className="flex flex-col items-start justify-start self-stretch pl-8 pt-3">
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  Inventering av din livssituation med hjälp av Fenix Familys digitala verktyg
                </li>
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  Digitalt testamente och framtidsfullmakt för nedladdning
                </li>
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  Trygg digital åtkomst och förvaring genom BankID
                </li>
              </ul>
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Mot en avgift - möjlighet till säker förvaring och övervakning av ditt testamente:
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              <ul className="flex flex-col items-start justify-start self-stretch pl-8 pt-3">
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  Utskick av testamente med förfrankerat kuvert för retur till Fenix Family och säker förvaring och övervakning under första året: 199 kronor
                </li>
                <li className="relative shrink grow basis-0 pb-4 text-[18px] font-normal italic leading-6 before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                  Säker förvaring och övervakning av testamente efter första året: 99 kronor per år
                </li>
              </ul>
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Det här är inte bara en tjänst – det är en möjlighet att slippa den där gnagande oron.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Medlemspriset efter 30 april 2025 är 3 999 kr (ordinarie pris 5 999 kr). Årsavgiften efter första året är 599 kr/år.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              Vi reserverar oss för eventuella fel och förbehåller oss rätten att
              dra tillbaka eller ändra erbjudandet utan föregående meddelande.
            </p>
            <p className="mb-3 whitespace-pre-wrap">
              &nbsp;
            </p>
            <div className="mb-3 justify-center">
              {renderAcuityCalendarButton(
                  ` !bg-deep-blue !text-white hover:!border-[#6F86A0] hover:!bg-[#6F86A0]`,
                  `#fff`
              )}
            </div>
          </>
        );
      default:
        return (
          <>
            All livsplanering du behöver för{" "}
            <span className="font-intermedium text-[#5F3741]">
                            {partner?.price ? partner.price : defaultPackagePrice}:-
                          </span>{" "}
            {partner?.price &&
                Number(partner?.price) < Number(defaultPackagePrice) &&
                `(Ordinarie pris ${defaultPackagePrice}:-)`}
            <p>
              &nbsp;
            </p>
            {renderAcuityCalendarButton(
                ` !bg-deep-blue !text-white hover:!border-[#6F86A0] hover:!bg-[#6F86A0]`,
                `#fff`
            )}
          </>
        );
    }
  };
  
  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-white",
            "fixed z-30 w-full transition-colors"
          )}
        >
          <HeadMenu
            hideMenu={true}
            affiliateLogo={partner?.logo?.href}
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={scrollPosition > 84 ? "#0C2632" : "#0C2632"}
          />
        </div>
        <div className="relative mt-24 w-full text-white">
          <div className="flex flex-col pt-2 lg:flex-row lg:gap-5 lg:pt-0">
            <div className="w-full lg:max-w-[50%]">
              <img
                src={partner?.hero ? partner?.hero.href : heroImg.href}
                alt="Fenix"
                className="lg:rounded-tr-0 relative h-auto min-h-[345px] w-[100%] max-w-[85%] rounded-tr-[13px] rounded-br-[13px] object-cover object-top lg:h-full lg:min-h-[640px] lg:max-w-[100%] lg:rounded-l-none lg:rounded-br-[24px] 2k:min-h-[800px] 4k:min-h-[1200px]"
                loading="lazy"
              />
            </div>
            <div className="w-full lg:max-w-[50%]">
              <div className="flex h-full flex-col justify-center pr-5 pl-5 text-deep-blue ">
                {(partner?.logo || partner?.logo2) && (
                  <img
                    src={
                      partner?.logo2 ? partner?.logo2.href : partner?.logo.href
                    }
                    alt="partner logo"
                    className="mb-4 mt-10 max-w-[215px] object-contain lg:mt-0 lg:max-w-[256px]"
                    loading="lazy"
                  />
                )}
                <h1 className="mb-4 font-interblack text-[28px] italic leading-[31px] lg:mb-4 lg:text-[48px] lg:leading-[53px]">
                  {getHeaderText(partner?.url)}
                </h1>
                <p className="max-w-[270px] font-interlight text-[18px] italic leading-[24px] lg:max-w-full">
                  {partner?.tagline || renderPartnerContent(partner?.url)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full bg-white pb-20 text-deep-blue">
          <div className="mx-auto px-5 pb-16 pt-20 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-16 lg:pt-28 xl:max-w-[1360px]">
            <div className="mx-auto w-full max-w-[1100px]">
              <h2 className="mb-3 max-w-[900px] font-interblack text-[28px] italic leading-[31px] lg:text-[48px] lg:leading-[53px]">
                Gör upp en plan utifrån din nuvarande och framtida
                livssituation.
              </h2>
              <p className="mb-3 max-w-[900px] whitespace-pre-wrap font-interlight italic">
                Juridik, avtal och testamenten kan upplevas som krångligt,
                kanske till och med lite obehagligt. Tillsammans ska vi få det
                att kännas precis tvärtom: tryggt, enkelt och självklart. Att ta
                tag i de här frågorna kommer inte bara att ge dig lugn och ro
                här och nu. Det kommer även underlätta framtiden för både dig
                och dina närmaste.
              </p>
              <span className="mt-0 inline-flex items-center font-intermedium text-[14px] uppercase tracking-[0.12em]">
                Så här funkar det {getShortDownArrowIcon("#0C2632")}
              </span>
            </div>
          </div>
          <PackageSection
            price={partner?.price ? partner.price : defaultPackagePrice}
            fee={partner?.fee ? partner.fee : "599"}
            displayTrustWidget={true}
            list={productAffiliateList}
            partner={partner}
          />
        </div>
        <div className="relative w-full bg-[#F3F0E680] text-deep-blue">
          <GifSection buttonOnClick={() => setShowConversationModal(true)} />
          {partner?.url !== "svenskhypotekspension" && (
            <div className="mx-auto px-5 pt-16 pb-10 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-20 lg:pt-24 xl:max-w-[1360px]">
              <div
                className={`flex flex-col gap-3 lg:flex-row lg:justify-between ${
                  partner?.contact ? "lg:items-center" : "lg:items-end"
                }`}
              >
                <div className="w-full max-w-[760px]">
                  <img
                      src={gifThumb.href}
                      alt="conversation flow"
                      className="min-h-[510px] object-cover drop-shadow-[4px_2px_35px_0px_rgba(0,0,0,0.08)]"
                      loading="lazy"
                  />
                </div>
                <div className="max-w-[530px]">
                  <h3 className="mb-3 mt-10 font-interblack text-[22px] font-extrabold italic leading-[22px] lg:text-[36px] lg:leading-[40px]">
                    Ett snabbt, tryggt och enkelt sätt att reda ut svåra saker.
                  </h3>
                  <p className="font-interlight text-base leading-[22px]">
                    Korrekt skrivna avtal för livets olika skeden kan bespara dig
                    och din familj mycket besvär. I vår värld är de här sakerna inte
                    heller något dyrt eller krångligt, utan går nästan lika snabbt
                    och enkelt som att dricka en kopp kaffe.
                  </p>
                  {!partner?.contact && (
                    <p className="mt-4 flex flex-wrap items-center font-intermedium text-[18px] italic">
                      All livsplanering du behöver från{" "}
                      <span className="px-2 font-interblack text-[22px] text-[#5F3741] lg:text-[36px]">
                        {partner?.price ? partner.price : defaultPackagePrice}:-
                      </span>
                      {partner?.price &&
                        Number(partner?.price) < Number(defaultPackagePrice) &&
                        `(Ordinarie pris ${defaultPackagePrice}:-)`}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="mx-auto px-5 pt-16 pb-28 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-36 lg:pt-16 xl:max-w-[1360px]">
            <div className="mx-auto mb-10 flex max-w-[1100px] flex-col items-center justify-around gap-10 lg:flex-row lg:items-start">
              {steps.map((step, index) => (
                <div
                  key={step.title}
                  className="flex shrink-0 grow basis-0 flex-col items-center justify-center gap-5"
                >
                  <div className="flex h-[152px] w-[152px] items-center justify-center rounded-full bg-white">
                    <img
                      src={step.icon.href}
                      className="w-[50px]"
                      loading="lazy"
                      alt="Fenix"
                    />
                  </div>
                  <div className="flex max-w-[310px] flex-col text-center text-dark-blue">
                    <p className="mb-4 font-intermedium text-base uppercase tracking-[0.25em]">
                      Steg {index + 1}
                    </p>
                    <p className="font-internormal text-[13px] leading-[15px] ">
                      {step.title}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              {renderLoginButton(
                ` !bg-deep-blue !text-white hover:!border-[#6F86A0] hover:!bg-[#6F86A0]`,
                `#fff`
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="relative w-full bg-deep-blue text-white">
            <div className="mx-auto px-5 pt-16 pb-20 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-24 lg:pt-24 xl:max-w-[1360px]">
              <div className="quote-block relative max-w-[970px] rounded-[3px] pt-10 pb-8 lg:pb-12 lg:pt-20">
                <span className="quote-icon absolute -left-0.5 top-5 opacity-40 lg:-left-3 lg:top-10">
                  {getQuoteIcon(
                    "#CECABA",
                    isDesktop ? "91" : "48px",
                    isDesktop ? "76" : "40px"
                  )}
                </span>
                <div className="z-1 relative font-interlight text-[24px] italic leading-[29px] lg:text-[42px] lg:leading-[50.4px]">
                  Jag är mycket nöjd med Fenix Familys tjänst. Du får hjälp
                  snabbt. Det är lätt att hitta den information du letar efter.
                  Du känner dig trygg hela vägen."
                  <div className="mt-10 flex flex-col items-start lg:flex-row lg:items-end">
                    {renderLoginButton(` py-3 not-italic `, `#CECABA`)}
                    <div className="-ml-9 mt-12 lg:ml-0 lg:mt-0">
                      <TrustBox
                        template={"5419b732fbfb950b10de65e5"}
                        height={"24px"}
                        theme={"dark"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative w-full bg-white text-deep-blue">
            <div className="mx-auto px-5 pt-12 pb-16 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-60 lg:pt-36 xl:max-w-[1360px]">
              <AdvisorToggleSection dynamicNumber={true} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ConversationEmbedded
        showInline={false}
        showModalOverride={showConversationModal}
      />
    </ServiceSectionProvider>
  );
}
